:root {
  --fill-color: rgba(0, 0, 0, 0.63);
  --border-color: rgba(230, 157, 101, 0.77);
}
html {
  scroll-behavior: smooth;
}
body {
  font-size: 21px;
  overflow-x: hidden;
  text-align: center;
  background: black;
}

body,
button,
.form-control {
  color: #ffffff;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: 400;
}

h1 {
  color: #111;
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  display: 'relative';
  margin-top: 0vh;
}

button {
  cursor: pointer;
}
a {
  transition: all 0.3s ease;
  color: white;
  text-decoration-line: none;
}
button {
  transition: all 0.3s ease;
}
a:hover,
a:focus {
  color: var(--border-color);
  text-decoration: none;
  outline: none;
}
.tm-section-welcome {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.tm-section-contact {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.tm-section-gallery {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.tm-gallery {
  margin: 0% 15%;
  border: 2px solid var(--border-color);
  background: var(--fill-color);
}

.tm-welcome {
  min-height: 100vh;
}

.tm-section-title {
  font-size: 2.6rem;
  margin-bottom: 1rem;
}

.tm-site-title-wrap {
  padding-top: 10vh;
  padding-bottom: 100px;
}

.tm-gallery-title-wrap {
  padding-top: 3vh;
  padding-bottom: 1vh;
}

.tm-site-title {
  border: 2px solid var(--border-color);
  color: #ffffff;
  display: inline-block;
  font-size: 3rem;
  padding: 30px 100px;
  background: var(--fill-color);
  width: 59%;
}

.tm-textbox {
  max-width: 600px;
  width: 100%;
  margin: 10px 15vw;
  padding: 50px;
  overflow: auto;
  background-color: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(0, 0, 0, 1)
  );
  border: 2px solid var(--border-color);
  text-align: left;
  background: var(--fill-color);
}

.tm-textbox-full-height {
  min-height: 100vh;
  margin: 0px 50vw;
  padding-top: 10vh;
  padding-bottom: 10vh;
  border: 2px solid var(--border-color);
  overflow-y: hidden;
}

.tm-btn,
.tm-btn:visited {
  display: block;
  float: right;
  font-size: 1.8rem;
  font-weight: 300;
  color: white;
  background-color: var(--border-color);
  padding: 15px 30px;
  cursor: pointer;
  border: none;
}

.tm-btn:hover,
.tm-btn:focus {
  color: white;
  background-color: #e69d65;
}



@media screen and (max-height: 639px) {
  .tm-section-home {
    min-height: 100%;
  }
}

/* Media Queries */
@media screen and (max-width: 991px) {
  .tm-gallery-content {
    padding-top: 150px;
  }
  .tm-section-gallery {
    min-height: 900px;
  }
  .tm-page-content-width,
  .tm-content-box {
    min-height: 1200px;
  }
}

@media screen and (max-width: 767px) {
  .tm-gallery-content {
    padding-top: 100px;
  }
  .tm-content-box {
    padding: 100px 60px 100px;
  }
  .tm-section-gallery {
    min-height: 1050px;
  }
  .tm-page-content-width,
  .tm-content-box {
    min-height: 1050px;
  }
  .tm-site-title-wrap {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .tm-gallery{
    margin: 0;
  }
}

@media screen and (max-height: 639px) {
  .tm-section-home {
    min-height: 100%;
  }
}

@media screen and (max-width: 575px) {
  .tm-gallery-content {
    padding-bottom: 150px;
  }
}

@media screen and (max-width: 480px) {
  .tm-media {
    flex-direction: column;
    align-items: center;
  }

  .tm-media-icon {
    margin-right: 0;
    margin-bottom: 30px;
  }
  .tm-site-title {
    font-size: 2rem;
    background: var(--fill-color);
    width: auto;
  }
  .tm-textbox {
    margin: 0;
    width: auto;
    background: var(--fill-color);
  }
}
